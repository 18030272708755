import moment from "moment";
import React, { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  OverlayTrigger,
  Row,
  Table,
  Jumbotron,
  Tooltip,
} from "react-bootstrap";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import { Helper } from "../../helper";
import CustomStyle from "../../styles";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { useOrders } from "./useOrders";
import { OwnerDropdown, ProductDropdown, Remark } from "./utils";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const deliveryModalStyles = {
  content: {
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
  },
};

Modal.setAppElement("#root");
function Orders() {
  const {
    setSelectedForInterStateDelivery,
    bookOrder,
    bookInterstateOrder,
    quote,
    setSelectedVehicle,
    selectedVehicle,
    getQuote,
    submitDeliveryRequest,
    productsForDelivery,
    getVehicles,
    vehicles,
    deliveryModalOpen,
    setDeliveryModalOpen,
    selectPendingDelivery,
    deliveryResults,
    pickupResults,
    navState,
    setNavState,
    selectedOrderIDs,
    handleCheckbox,
    loading,
    confirmLoading,
    setConfirmLoading,
    shipLoading,
    setShipLoading,
    completeLoading,
    setCompleteLoading,
    declineLoading,
    setDeclineLoading,
    paymentLoading,
    setPaymentLoading,
    orders,
    results,
    csvResults,
    limit,
    setLimit,
    searchValidated,
    confirmData,
    setConfirmData,
    shipData,
    setShipData,
    completeData,
    setCompleteData,
    declineData,
    setDeclineData,
    paymentData,
    interstateDeliveryResults,
    setPaymentData,
    processedWarehouses,
    currentWarehouse,
    context,
    keywordInput,
    trackInput,
    prepareForCSV,
    fetchWarehouseData,
    fetchWarehouseOrders,
    fetchData,
    handleSearch,
    declineOrder,
    declineOrderSubmit,
    confirmOrder,
    confirmOrderSubmit,
    shipOrder,
    shipOrderSubmit,
    completeOrder,
    completeOrderSubmit,
    paymentOrder,
    paymentOrderSubmit,
    handlePageClick,
    getOrderValue,
    shipInterstateData,
    setShipInterstateData,
    shipInterstataOrder,
    setStatus,
    status,
  } = useOrders();

  useEffect(() => {
    fetchWarehouseData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [limit, navState, status]);

  useEffect(() => {
    prepareForCSV();
  }, [results]);

  // ["Pending", "Completed", "Shipped", "Declined"]
  const statusOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Completed", value: "Completed" },
    { label: "Shipped", value: "Shipped" },
    { label: "Declined", value: "Declined" },
    { label: "Picked Up", value: "Confirmed" },
  ];

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="9">
            <Card className="card-tasks">
              <Card.Header>
                <Row>
                  <Col md="6">
                    <i
                      className="nc-icon nc-delivery-fast"
                      style={{ fontSize: 50 }}
                    ></i>
                    {!currentWarehouse ? (
                      <Card.Title as="h4">All Orders</Card.Title>
                    ) : (
                      <Card.Title as="h4">
                        Orders in
                        {" " + currentWarehouse.name}
                      </Card.Title>
                    )}
                    {!Helper.isAdministrator(context.authState?.admin) && (
                      <Row style={{ marginLeft: 2 }}>
                        <select onChange={(e) => setLimit(e.target.value)}>
                          <option>50</option>
                          <option>100</option>
                          <option>1000</option>
                          <option>5000</option>
                        </select>
                        <div style={{ marginLeft: 2 }}></div>
                        <Button className="btn-sm">
                          <CSVLink data={csvResults} className="card-category">
                            Export CSV
                          </CSVLink>
                        </Button>
                      </Row>
                    )}
                  </Col>
                  <Col md="6">
                    <Form
                      className="container"
                      noValidate
                      validated={searchValidated}
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSearch(e);
                      }}
                    >
                      <Row>
                        <Col md="10">
                          <Form.Group>
                            <label>Order ID</label>
                            <Form.Control
                              placeholder="Search Order By ID"
                              type="text"
                              required
                              ref={keywordInput}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please enter a keyword.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group>
                            <label>Search</label>
                            <Button
                              className="btn-fill form-control"
                              type="submit"
                              variant="primary"
                              style={{
                                background: CustomStyle.colors.primary,
                                borderColor: CustomStyle.colors.primary,
                              }}
                            >
                              <i className="nc-icon nc-zoom-split"></i>
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                    <Form.Group className="px-3">
                      <Button
                        className="btn-fill form-control"
                        type="submit"
                        variant="primary"
                        style={{
                          background: CustomStyle.colors.primary,
                          borderColor: CustomStyle.colors.primary,
                        }}
                        onClick={() =>
                          fetchData(
                            1,
                            `${
                              process.env.REACT_APP_API_V2
                            }/admin/orders?createdAt=desc&limit=${limit}&page=${1}`
                          )
                        }
                      >
                        View All Orders
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="table-full-width">
                    <Row>
                      <Col xs={8}>

                        <Nav
                          variant="tabs"
                          defaultActiveKey={navState}
                          style={{ marginBottom: 8 }}
                        >
                          <Nav.Item>
                            <Nav.Link
                              eventKey="pickup"
                              onClick={() => setNavState("pickup")}
                            >
                              Pick up
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="delivery"
                              onClick={() => setNavState("delivery")}
                            >
                              Intrastate Delivery
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="interstate-delivery"
                              onClick={() => setNavState("interstate-delivery")}
                            >
                              Interstate Delivery
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>

                      <Col>
                        <select
                          className="custom-select custom-select-lg"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          {statusOptions.map(({ label, value }) => (
                            <option key={label} value={value}>
                              {label}
                            </option>
                          ))}
           </select>
                      </Col>
                    </Row>

                    <Table>
                      <tbody>
                        {orders &&
                        orders.results &&
                        orders.results?.length > 0 ? (
                          orders.results?.map((item, index) => {
                            return (
                              <tr key={index.toString()}>
                                <td
                                  style={{
                                    minWidth: "40%",
                                  }}
                                >
                                  <div className="media">
                                    <i
                                      className="nc-icon nc-circle-09 text-warning"
                                      style={{
                                        fontSize: 30,
                                      }}
                                    ></i>

                                    <div className="media-content pl-2">
                                      <div>
                                        <span>Owner: </span>
                                        <OwnerDropdown item={item} />
                                      </div>
                                      <div>
                                        <span>Order ID: </span>
                                        <Link
                                          to={`finance/${item?.userId?._id}?reference=${item?.id}`}
                                        >
                                          <b>{item?.id}</b>
                                        </Link>
                                      </div>
                                      <div>
                                        <span>Status: </span>
                                        <b
                                          className={
                                            item?.status == "completed"
                                              ? "text-success"
                                              : item?.status == "shipped"
                                              ? "text-primary"
                                              : item?.status == "confirmed"
                                              ? "text-info"
                                              : item?.status == "declined"
                                              ? "text-danger"
                                              : item?.status == "pending"
                                              ? "text-warning"
                                              : "text-dark"
                                          }
                                        >
                                          {item?.status}
                                        </b>
                                      </div>
                                      {item?.trackId && (
                                        <div>
                                          <span>Tracking ID: </span>
                                          <b>{item?.trackId}</b>
                                        </div>
                                      )}

                                      <div>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Store Address:{" "}
                                        </span>
                                        <b>
                                          {
                                            item?.deliveryAddress?.warehouse
                                              ?.name
                                          }
                                        </b>
                                      </div>
                                      {item?.deliveryOption == "delivery" && (
                                        <div>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Delivery Address:{" "}
                                          </span>
                                          <b>
                                            {
                                              item?.deliveryAddress
                                                ?.userLocation?.address
                                            }
                                          </b>
                                        </div>
                                      )}
                                      <div>
                                        <span>Purchase: </span>
                                        <ProductDropdown item={item} />
                                      </div>
                                      <div>
                                        <span>Order Value: </span>
                                        {Helper.formatToNaira(
                                          getOrderValue(item?.cart)
                                        )}
                                      </div>
                                      <div>
                                        <span>Amount Paid: </span>
                                        <b>
                                          {Helper.formatToNaira(
                                            item?.totalAmount
                                          )}
                                        </b>{" "}
                                        {item?.discountedFee ? (
                                          <Link
                                            to={`finance/${item?.userId?._id}?reference=${item?.id}`}
                                          >
                                            <small>(discounted)</small>
                                          </Link>
                                        ) : null}
                                      </div>

                                      <div>
                                        <span>Payment Status: </span>
                                        <b
                                          className={
                                            item?.paymentStatus == "success"
                                              ? "text-success"
                                              : item?.paymentStatus == "pending"
                                              ? "text-warning"
                                              : "text-dark"
                                          }
                                        >
                                          {item?.paymentStatus}
                                        </b>
                                      </div>

                                      <div>
                                        <span>Customer Phone: </span>
                                        <b>{item?.customerPhone}</b>
                                      </div>

                                      <small>
                                        Requested{" "}
                                        {moment(item?.createdAt).fromNow()}
                                      </small>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <Remark user={item?.userId} />
                                </td>
                                {Helper.isAdmin(context.authState?.admin) ||
                                Helper.isAdministrator(
                                  context.authState?.admin
                                ) ||
                                Helper.isPopulator(context.authState?.admin) ? (
                                  <td className="td-actions text-right">
                                    {item?.status != "declined" &&
                                    item?.status == "pending" &&
                                    item?.status != "confirmed" ? (
                                      <div className="mb-2">
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip-938342127">
                                              Click to confirm order
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn btn-fill px-3"
                                            type="button"
                                            variant="info"
                                            onClick={async () =>
                                              confirmOrder(item)
                                            }
                                          >
                                            <i className="nc-icon nc-notification-70"></i>
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    ) : null}


                                    {item?.status != "declined" &&
                                    item?.status == "confirmed" &&
                                    item?.status != "shipped" ? (
                                      <div className="mb-2">
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip-938342127">
                                              {item?.deliveryOption == "pick-up"
                                                ? "Click to confirm that Pick up is ready"
                                                : "Click to ship order"}
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn btn-fill px-3 mb-2"
                                            type="button"
                                            variant="primary"
                                            onClick={async () =>
                                              shipOrder(item)
                                            }
                                          >
                                            {item?.deliveryOption ==
                                            "pick-up" ? (
                                              <i className="nc-icon nc-time-alarm"></i>
                                            ) : (
                                              <i className="nc-icon nc-bus-front-12"></i>
                                            )}
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    ) : null}

                                    {item?.status != "declined" &&
                                    item?.status == "shipped" &&
                                    item?.status != "complete" ? (
                                      <div className="mb-2">
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip-938342127">
                                              Click to complete order
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn btn-fill px-3"
                                            type="button"
                                            variant="success"
                                            onClick={async () =>
                                              completeOrder(item)
                                            }
                                          >
                                            <i className="nc-icon nc-check-2"></i>
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    ) : null}

                                    {item?.paymentStatus == "pending" &&
                                    item?.status != "completed" &&
                                    item?.status != "declined" ? (
                                      <div className="mb-2">
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip-938342127">
                                              Click to confirm cash payment
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn btn-fill px-3"
                                            type="button"
                                            variant="warning"
                                            onClick={async () =>
                                              paymentOrder(item)
                                            }
                                          >
                                            <i className="nc-icon nc-money-coins"></i>
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    ) : null}

                                    {item?.status != "completed" &&
                                    item?.status != "declined" ? (
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip id="tooltip-938342127">
                                            Click to decline order
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          className="btn btn-fill px-3"
                                          type="button"
                                          variant="danger"
                                          onClick={async () =>
                                            declineOrder(item)
                                          }
                                        >
                                          <i className="nc-icon nc-simple-remove"></i>
                                        </Button>
                                      </OverlayTrigger>
                                    ) : null}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              style={{
                                textAlign: "center",
                                color: CustomStyle.colors.danger,
                              }}
                            >
                              <small>No order found</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {/* {navState == "pickup" && (
                      <Table>
                        <tbody>
                          {pickupResults && pickupResults?.length > 0 ? (
                            pickupResults?.map((item, index) => {
                              return (
                                <tr key={index.toString()}>
                                  <td
                                    style={{
                                      minWidth: "40%",
                                    }}
                                  >
                                    <div className="media">
                                      <i
                                        className="nc-icon nc-circle-09 text-warning"
                                        style={{
                                          fontSize: 30,
                                        }}
                                      ></i>

                                      <div className="media-content pl-2">
                                        <div>
                                          <span>Owner: </span>
                                          <OwnerDropdown item={item} />
                                        </div>
                                        <div>
                                          <span>Order ID: </span>
                                          <Link
                                            to={`finance/${item?.userId?._id}?reference=${item?.id}`}
                                          >
                                            <b>{item?.id}</b>
                                          </Link>
                                        </div>
                                        <div>
                                          <span>Status: </span>
                                          <b
                                            className={
                                              item?.status == "completed"
                                                ? "text-success"
                                                : item?.status == "shipped"
                                                ? "text-primary"
                                                : item?.status == "confirmed"
                                                ? "text-info"
                                                : item?.status == "declined"
                                                ? "text-danger"
                                                : item?.status == "pending"
                                                ? "text-warning"
                                                : "text-dark"
                                            }
                                          >
                                            {item?.status}
                                          </b>
                                        </div>
                                        {item?.trackId && (
                                          <div>
                                            <span>Tracking ID: </span>
                                            <b>{item?.trackId}</b>
                                          </div>
                                        )}

                                        <div>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Store Address:{" "}
                                          </span>
                                          <b>
                                            {
                                              item?.deliveryAddress?.warehouse
                                                ?.name
                                            }
                                          </b>
                                        </div>
                                        {item?.deliveryOption == "delivery" && (
                                          <div>
                                            <span
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              Delivery Address:{" "}
                                            </span>
                                            <b>
                                              {
                                                item?.deliveryAddress
                                                  ?.userLocation?.address
                                              }
                                            </b>
                                          </div>
                                        )}
                                        <div>
                                          <span>Purchase: </span>
                                          <ProductDropdown item={item} />
                                        </div>
                                        <div>
                                          <span>Order Value: </span>
                                          {Helper.formatToNaira(
                                            getOrderValue(item?.cart)
                                          )}
                                        </div>
                                        <div>
                                          <span>Amount Paid: </span>
                                          <b>
                                            {Helper.formatToNaira(
                                              item?.totalAmount
                                            )}
                                          </b>{" "}
                                          {item?.discountedFee ? (
                                            <Link
                                              to={`finance/${item?.userId?._id}?reference=${item?.id}`}
                                            >
                                              <small>(discounted)</small>
                                            </Link>
                                          ) : null}
                                        </div>

                                        <div>
                                          <span>Payment Status: </span>
                                          <b
                                            className={
                                              item?.paymentStatus == "success"
                                                ? "text-success"
                                                : item?.paymentStatus ==
                                                  "pending"
                                                ? "text-warning"
                                                : "text-dark"
                                            }
                                          >
                                            {item?.paymentStatus}
                                          </b>
                                        </div>

                                        <div>
                                          <span>Customer Phone: </span>
                                          <b>{item?.customerPhone}</b>
                                        </div>

                                        <small>
                                          Requested{" "}
                                          {moment(item?.createdAt).fromNow()}
                                        </small>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <Remark user={item?.userId} />
                                  </td>
                                  {Helper.isAdmin(context.authState?.admin) ||
                                  Helper.isAdministrator(
                                    context.authState?.admin
                                  ) ||
                                  Helper.isPopulator(
                                    context.authState?.admin
                                  ) ? (
                                    <td className="td-actions text-right">
                                      {item?.status != "declined" &&
                                      item?.status == "pending" &&
                                      item?.status != "confirmed" ? (
                                        <div className="mb-2">
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip-938342127">
                                                Click to confirm order
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              className="btn btn-fill px-3"
                                              type="button"
                                              variant="info"
                                              onClick={async () =>
                                                confirmOrder(item)
                                              }
                                            >
                                              <i className="nc-icon nc-notification-70"></i>
                                            </Button>
                                          </OverlayTrigger>
                                        </div>
                                      ) : null}

                                      {item?.status != "declined" &&
                                      item?.status == "confirmed" &&
                                      item?.status != "shipped" ? (
                                        <div className="mb-2">
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip-938342127">
                                                {item?.deliveryOption ==
                                                "pick-up"
                                                  ? "Click to confirm that Pick up is ready"
                                                  : "Click to ship order"}
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              className="btn btn-fill px-3 mb-2"
                                              type="button"
                                              variant="primary"
                                              onClick={async () =>
                                                shipOrder(item)
                                              }
                                            >
                                              {item?.deliveryOption ==
                                              "pick-up" ? (
                                                <i className="nc-icon nc-time-alarm"></i>
                                              ) : (
                                                <i className="nc-icon nc-bus-front-12"></i>
                                              )}
                                            </Button>
                                          </OverlayTrigger>
                                        </div>
                                      ) : null}

                                      {item?.status != "declined" &&
                                      item?.status == "shipped" &&
                                      item?.status != "complete" ? (
                                        <div className="mb-2">
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip-938342127">
                                                Click to complete order
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              className="btn btn-fill px-3"
                                              type="button"
                                              variant="success"
                                              onClick={async () =>
                                                completeOrder(item)
                                              }
                                            >
                                              <i className="nc-icon nc-check-2"></i>
                                            </Button>
                                          </OverlayTrigger>
                                        </div>
                                      ) : null}

                                      {item?.paymentStatus == "pending" &&
                                      item?.status != "completed" &&
                                      item?.status != "declined" ? (
                                        <div className="mb-2">
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip-938342127">
                                                Click to confirm cash payment
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              className="btn btn-fill px-3"
                                              type="button"
                                              variant="warning"
                                              onClick={async () =>
                                                paymentOrder(item)
                                              }
                                            >
                                              <i className="nc-icon nc-money-coins"></i>
                                            </Button>
                                          </OverlayTrigger>
                                        </div>
                                      ) : null}

                                      {item?.status != "completed" &&
                                      item?.status != "declined" ? (
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip-938342127">
                                              Click to decline order
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn btn-fill px-3"
                                            type="button"
                                            variant="danger"
                                            onClick={async () =>
                                              declineOrder(item)
                                            }
                                          >
                                            <i className="nc-icon nc-simple-remove"></i>
                                          </Button>
                                        </OverlayTrigger>
                                      ) : null}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                style={{
                                  textAlign: "center",
                                  color: CustomStyle.colors.danger,
                                }}
                              >
                                <small>No order found</small>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    )} */}

                    {/* {navState == "delivery" && (
                      <>
                        <div
                          style={{
                            height: 50,
                            backgroundColor: "lightgray",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: 15,
                          }}
                        >
                          <>
                            <span
                              style={{
                                marginRight: 10,
                              }}
                            >
                              Select confirmed
                            </span>
                            <input
                              type="checkbox"
                              checked={selectedOrderIDs.length > 0}
                              onChange={selectPendingDelivery}
                            />
                          </>

                          {selectedOrderIDs.length > 0 && (
                            <Button
                              className="btn btn-fill px-3"
                              type="button"
                              variant="info"
                              onClick={() => setDeliveryModalOpen(true)}
                            >
                              Create delivery
                            </Button>
                          )}
                        </div>
                        <Table>
                          <tbody>
                            {deliveryResults && deliveryResults?.length > 0 ? (
                              deliveryResults?.map((item, index) => {
                                return (
                                  <tr key={index.toString()}>
                                    <td
                                      style={{
                                        minWidth: "40%",
                                      }}
                                    >
                                      <div className="media">
                                        {item?.status == "confirmed" ? (
                                          <input
                                            checked={
                                              selectedOrderIDs.includes(
                                                item?._id
                                              )
                                                ? true
                                                : false
                                            }
                                            type="checkbox"
                                            onChange={() =>
                                              handleCheckbox(item)
                                            }
                                          />
                                        ) : (
                                          <i
                                            className="nc-icon nc-circle-09 text-warning"
                                            style={{
                                              fontSize: 30,
                                            }}
                                          ></i>
                                        )}
                                        <div className="media-content pl-2">
                                          <div>
                                            <span>Owner: </span>
                                            <OwnerDropdown item={item} />
                                          </div>
                                          <div>
                                            <span>Order ID: </span>
                                            <Link
                                              to={`finance/${item?.userId?._id}?reference=${item?.id}`}
                                            >
                                              <b>{item?.id}</b>
                                            </Link>
                                          </div>
                                          <div>
                                            <span>Status: </span>
                                            <b
                                              className={
                                                item?.status == "completed"
                                                  ? "text-success"
                                                  : item?.status == "shipped"
                                                  ? "text-primary"
                                                  : item?.status == "confirmed"
                                                  ? "text-info"
                                                  : item?.status == "declined"
                                                  ? "text-danger"
                                                  : item?.status == "pending"
                                                  ? "text-warning"
                                                  : "text-dark"
                                              }
                                            >
                                              {item?.status}
                                            </b>
                                          </div>
                                          {item?.trackId && (
                                            <div>
                                              <span>Tracking ID: </span>
                                              <b>{item?.trackId}</b>
                                            </div>
                                          )}

                                          <div>
                                            <span
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              Store Address:{" "}
                                            </span>
                                            <b>
                                              {
                                                item?.deliveryAddress?.warehouse
                                                  ?.name
                                              }
                                            </b>
                                          </div>
                                          {item?.deliveryOption ==
                                            "delivery" && (
                                            <div>
                                              <span
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                Delivery Address:{" "}
                                              </span>
                                              <b>
                                                {
                                                  item?.deliveryAddress
                                                    ?.userLocation?.address
                                                }
                                              </b>
                                            </div>
                                          )}
                                          <div>
                                            <span>Purchase: </span>
                                            <ProductDropdown item={item} />
                                          </div>
                                          <div>
                                            <span>Order Value: </span>
                                            {Helper.formatToNaira(
                                              getOrderValue(item?.cart)
                                            )}
                                          </div>
                                          <div>
                                            <span>Amount Paid: </span>
                                            <b>
                                              {Helper.formatToNaira(
                                                item?.totalAmount
                                              )}
                                            </b>{" "}
                                            {item?.discountedFee ? (
                                              <Link
                                                to={`finance/${item?.userId?._id}?reference=${item?.id}`}
                                              >
                                                <small>(discounted)</small>
                                              </Link>
                                            ) : null}
                                          </div>

                                          <div>
                                            <span>Payment Status: </span>
                                            <b
                                              className={
                                                item?.paymentStatus == "success"
                                                  ? "text-success"
                                                  : item?.paymentStatus ==
                                                    "pending"
                                                  ? "text-warning"
                                                  : "text-dark"
                                              }
                                            >
                                              {item?.paymentStatus}
                                            </b>
                                          </div>
                                          <div>
                                            <span>Customer Phone: </span>
                                            <b>{item?.customerPhone}</b>
                                          </div>
                                          <small>
                                            Requested{" "}
                                            {moment(item?.createdAt).fromNow()}
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <Remark user={item?.userId} />
                                    </td>
                                    {Helper.isAdmin(context.authState?.admin) ||
                                    Helper.isAdministrator(
                                      context.authState?.admin
                                    ) ||
                                    Helper.isPopulator(
                                      context.authState?.admin
                                    ) ? (
                                      <td className="td-actions text-right">
                                        {item?.status != "declined" &&
                                        item?.status == "pending" &&
                                        item?.status != "confirmed" ? (
                                          <div className="mb-2">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip id="tooltip-938342127">
                                                  Click to confirm order
                                                </Tooltip>
                                              }
                                            >
                                              <Button
                                                className="btn btn-fill px-3"
                                                type="button"
                                                variant="info"
                                                onClick={async () =>
                                                  confirmOrder(item)
                                                }
                                              >
                                                <i className="nc-icon nc-notification-70"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          </div>
                                        ) : null}

                                        {item?.status != "declined" &&
                                        item?.status == "confirmed" &&
                                        item?.status != "shipped" ? (
                                          <div className="mb-2">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip id="tooltip-938342127">
                                                  {item?.deliveryOption ==
                                                  "pick-up"
                                                    ? "Click to confirm that Pick up is ready"
                                                    : "Click to ship order"}
                                                </Tooltip>
                                              }
                                            >
                                              <Button
                                                className="btn btn-fill px-3 mb-2"
                                                type="button"
                                                variant="primary"
                                                onClick={async () => {
                                                  item?.deliveryOption ==
                                                  "pick-up"
                                                    ? shipOrder(item)
                                                    : handleCheckbox(item);
                                                }}
                                              >
                                                {item?.deliveryOption ==
                                                "pick-up" ? (
                                                  <i className="nc-icon nc-time-alarm"></i>
                                                ) : (
                                                  <i className="nc-icon nc-bus-front-12"></i>
                                                )}
                                              </Button>
                                            </OverlayTrigger>
                                          </div>
                                        ) : null}

                                        {item?.status != "declined" &&
                                        item?.status == "shipped" &&
                                        item?.status != "complete" ? (
                                          <div className="mb-2">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip id="tooltip-938342127">
                                                  Click to complete order
                                                </Tooltip>
                                              }
                                            >
                                              <Button
                                                className="btn btn-fill px-3"
                                                type="button"
                                                variant="success"
                                                onClick={async () =>
                                                  completeOrder(item)
                                                }
                                              >
                                                <i className="nc-icon nc-check-2"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          </div>
                                        ) : null}

                                        {item?.paymentStatus == "pending" &&
                                        item?.status != "completed" &&
                                        item?.status != "declined" ? (
                                          <div className="mb-2">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip id="tooltip-938342127">
                                                  Click to confirm cash payment
                                                </Tooltip>
                                              }
                                            >
                                              <Button
                                                className="btn btn-fill px-3"
                                                type="button"
                                                variant="warning"
                                                onClick={async () =>
                                                  paymentOrder(item)
                                                }
                                              >
                                                <i className="nc-icon nc-money-coins"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          </div>
                                        ) : null}

                                        {item?.status != "completed" &&
                                        item?.status != "declined" ? (
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip-938342127">
                                                Click to decline order
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              className="btn btn-fill px-3"
                                              type="button"
                                              variant="danger"
                                              onClick={async () =>
                                                declineOrder(item)
                                              }
                                            >
                                              <i className="nc-icon nc-simple-remove"></i>
                                            </Button>
                                          </OverlayTrigger>
                                        ) : null}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan="6"
                                  style={{
                                    textAlign: "center",
                                    color: CustomStyle.colors.danger,
                                  }}
                                >
                                  <small>No order found</small>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </>
                    )}
                    {navState == "interstate-delivery" && (
                      <Table>
                        <tbody>
                          {interstateDeliveryResults &&
                          interstateDeliveryResults?.length > 0 ? (
                            interstateDeliveryResults?.map((item, index) => {
                              return (
                                <tr key={index.toString()}>
                                  <td
                                    style={{
                                      minWidth: "40%",
                                    }}
                                  >
                                    <div className="media">
                                      <i
                                        className="nc-icon nc-circle-09 text-warning"
                                        style={{
                                          fontSize: 30,
                                        }}
                                      ></i>

                                      <div className="media-content pl-2">
                                        <div>
                                          <span>Owner: </span>
                                          <OwnerDropdown item={item} />
                                        </div>
                                        <div>
                                          <span>Order ID: </span>
                                          <Link
                                            to={`finance/${item?.userId?._id}?reference=${item?.id}`}
                                          >
                                            <b>{item?.id}</b>
                                          </Link>
                                        </div>
                                        <div>
                                          <span>Status: </span>
                                          <b
                                            className={
                                              item?.status == "completed"
                                                ? "text-success"
                                                : item?.status == "shipped"
                                                ? "text-primary"
                                                : item?.status == "confirmed"
                                                ? "text-info"
                                                : item?.status == "declined"
                                                ? "text-danger"
                                                : item?.status == "pending"
                                                ? "text-warning"
                                                : "text-dark"
                                            }
                                          >
                                            {item?.status}
                                          </b>
                                        </div>
                                        {item?.trackId && (
                                          <div>
                                            <span>Tracking ID: </span>
                                            <b>{item?.trackId}</b>
                                          </div>
                                        )}

                                        <div>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            Store Address:{" "}
                                          </span>
                                          <b>
                                            {
                                              item?.deliveryAddress?.warehouse
                                                ?.name
                                            }
                                          </b>
                                        </div>
                                        {item?.deliveryOption == "delivery" && (
                                          <div>
                                            <span
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              Delivery Address:{" "}
                                            </span>
                                            <b>
                                              {
                                                item?.deliveryAddress
                                                  ?.userLocation?.address
                                              }
                                            </b>
                                          </div>
                                        )}
                                        <div>
                                          <span>Purchase: </span>
                                          <ProductDropdown item={item} />
                                        </div>
                                        <div>
                                          <span>Order Value: </span>
                                          {Helper.formatToNaira(
                                            getOrderValue(item?.cart)
                                          )}
                                        </div>
                                        <div>
                                          <span>Amount Paid: </span>
                                          <b>
                                            {Helper.formatToNaira(
                                              item?.totalAmount
                                            )}
                                          </b>{" "}
                                          {item?.discountedFee ? (
                                            <Link
                                              to={`finance/${item?.userId?._id}?reference=${item?.id}`}
                                            >
                                              <small>(discounted)</small>
                                            </Link>
                                          ) : null}
                                        </div>

                                        <div>
                                          <span>Payment Status: </span>
                                          <b
                                            className={
                                              item?.paymentStatus == "success"
                                                ? "text-success"
                                                : item?.paymentStatus ==
                                                  "pending"
                                                ? "text-warning"
                                                : "text-dark"
                                            }
                                          >
                                            {item?.paymentStatus}
                                          </b>
                                        </div>
                                        <div>
                                          <span>Customer Phone: </span>
                                          <b>{item?.customerPhone}</b>
                                        </div>
                                        <small>
                                          Requested{" "}
                                          {moment(item?.createdAt).fromNow()}
                                        </small>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <Remark user={item?.userId} />
                                  </td>
                                  {Helper.isAdmin(context.authState?.admin) ||
                                  Helper.isAdministrator(
                                    context.authState?.admin
                                  ) ||
                                  Helper.isPopulator(
                                    context.authState?.admin
                                  ) ? (
                                    <td className="td-actions text-right">
                                      {item?.status != "declined" &&
                                      item?.status == "pending" &&
                                      item?.status != "confirmed" ? (
                                        <div className="mb-2">
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip-938342127">
                                                Click to confirm order
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              className="btn btn-fill px-3"
                                              type="button"
                                              variant="info"
                                              onClick={async () => {
                                                confirmOrder(item);
                                                setSelectedForInterStateDelivery(
                                                  item
                                                );
                                              }}
                                            >
                                              <i className="nc-icon nc-notification-70"></i>
                                            </Button>
                                          </OverlayTrigger>
                                        </div>
                                      ) : null}

                                      {item?.status != "declined" &&
                                      item?.status == "confirmed" &&
                                      item?.status != "shipped" ? (
                                        <div className="mb-2">
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip-938342127">
                                                {item?.deliveryOption ==
                                                "pick-up"
                                                  ? "Click to confirm that Pick up is ready"
                                                  : "Click to ship order"}
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              className="btn btn-fill px-3 mb-2"
                                              type="button"
                                              variant="primary"
                                              onClick={async () =>
                                                shipInterstataOrder(item)
                                              }
                                            >
                                              {item?.deliveryOption ==
                                              "pick-up" ? (
                                                <i className="nc-icon nc-time-alarm"></i>
                                              ) : (
                                                <i className="nc-icon nc-bus-front-12"></i>
                                              )}
                                            </Button>
                                          </OverlayTrigger>
                                        </div>
                                      ) : null}

                                      {item?.status != "declined" &&
                                      item?.status == "shipped" &&
                                      item?.status != "complete" ? (
                                        <div className="mb-2">
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip-938342127">
                                                Click to complete order
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              className="btn btn-fill px-3"
                                              type="button"
                                              variant="success"
                                              onClick={async () =>
                                                completeOrder(item)
                                              }
                                            >
                                              <i className="nc-icon nc-check-2"></i>
                                            </Button>
                                          </OverlayTrigger>
                                        </div>
                                      ) : null}

                                      {item?.paymentStatus == "pending" &&
                                      item?.status != "completed" &&
                                      item?.status != "declined" ? (
                                        <div className="mb-2">
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip-938342127">
                                                Click to confirm cash payment
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              className="btn btn-fill px-3"
                                              type="button"
                                              variant="warning"
                                              onClick={async () =>
                                                paymentOrder(item)
                                              }
                                            >
                                              <i className="nc-icon nc-money-coins"></i>
                                            </Button>
                                          </OverlayTrigger>
                                        </div>
                                      ) : null}

                                      {item?.status != "completed" &&
                                      item?.status != "declined" ? (
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip-938342127">
                                              Click to decline order
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn btn-fill px-3"
                                            type="button"
                                            variant="danger"
                                            onClick={async () =>
                                              declineOrder(item)
                                            }
                                          >
                                            <i className="nc-icon nc-simple-remove"></i>
                                          </Button>
                                        </OverlayTrigger>
                                      ) : null}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                style={{
                                  textAlign: "center",
                                  color: CustomStyle.colors.danger,
                                }}
                              >
                                <small>No order found</small>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    )} */}
                  </div>
                )}
              </Card.Body>
              <Card.Footer>
                {orders && orders?.totalPages ? (
                  <ReactPaginate
                    previousLabel={
                      <i className="nc-icon ml-1 nc-stre-left"></i>
                    }
                    nextLabel={<i className="nc-icon ml-1 nc-stre-right"></i>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={orders?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Card.Footer>
            </Card>
          </Col>
          <Col md="3">
            <Card className="card-tasks">
              <Card.Header>
                <Card.Title as={"h4"}>Warehouses</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="table-full-width">
                  <Table>
                    <tbody>
                      {processedWarehouses?.length > 0 ? (
                        processedWarehouses?.map((item, index) => (
                          <tr key={index.toString()}>
                            <td
                              style={{
                                justifyContent: "space-between",
                                display: "flex",
                                backgroundColor:
                                  currentWarehouse?.name?.toLowerCase() ===
                                  item?.name?.toLowerCase()
                                    ? "#2c237a"
                                    : "#fff",
                                color:
                                  currentWarehouse?.name?.toLowerCase() ===
                                  item?.name?.toLowerCase()
                                    ? "#fff"
                                    : "#000",
                              }}
                              className={
                                currentWarehouse?.name?.toLowerCase() ===
                                item?.name?.toLowerCase()
                                  ? "#2c237a"
                                  : "#fff"
                              }
                            >
                              <div
                                onClick={() => fetchWarehouseOrders(item)}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flex: 1,
                                }}
                              >
                                <b>{item?.name}</b>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="6"
                            style={{
                              textAlign: "center",
                              color: CustomStyle.colors.danger,
                            }}
                          >
                            <small>No warehouse found</small>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        isOpen={deliveryModalOpen}
        onRequestClose={() => setDeliveryModalOpen(false)}
        onAfterOpen={getVehicles}
        style={deliveryModalStyles}
        contentLabel="Set up delivery"
      >
        {deliveryModalOpen ? (
          <Container fluid>
            <Row>
              <Col className="p-0" md="4">
                <h5
                  style={{
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <b>Set Up Delivery</b>
                </h5>
                <br />
                <select
                  style={{
                    height: 45,
                    width: "100%",
                    marginBottom: 15,
                  }}
                  onChange={(e) => setSelectedVehicle(e.target.value)}
                >
                  {/* <option value={null}>Select a vehicle</option> */}
                  {vehicles.map((vehicle) => (
                    <option key={vehicle.VehicleID} value={vehicle.VehicleID}>
                      {vehicle.Name}
                    </option>
                  ))}
                </select>
                <br />
                {quote == null && (
                  <Button
                    className="btn-fill form-control pull-right"
                    variant="primary"
                    type="button"
                    onClick={getQuote}
                    disabled={!selectedVehicle}
                  >
                    {loading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Get Quote"
                    )}
                  </Button>
                )}
                <div style={{ marginTop: 25, marginBottom: 25 }}>
                  {quote && (
                    <ul style={{ padding: 0 }}>
                      <ol style={{ padding: 0 }}>
                        Vehicle: {vehicles[parseInt(selectedVehicle) - 1]?.Name}
                      </ol>
                      <ol style={{ padding: 0 }}>Company: {quote?.Name}</ol>
                      <ol style={{ padding: 0 }}>
                        Total Price: ₦ {quote?.TotalPrice}
                      </ol>
                    </ul>
                  )}
                </div>

                <br />

                <Row>
                  <Col className="" md="4" />
                  <Col className="" md="4">
                    <Form.Group>
                      <label htmlFor=""></label>
                      <Button
                        onClick={() => {
                          setQuote(null);
                          setDeliveryModalOpen(false);
                        }}
                        className="btn-fill form-control pull-right"
                        variant="default"
                        type="button"
                      >
                        Close
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col className="" md="4">
                    <Form.Group>
                      <label htmlFor=""></label>
                      <Button
                        className="btn-fill form-control pull-right"
                        variant="primary"
                        type="button"
                        disabled={!quote}
                        onClick={bookOrder}
                      >
                        {loading ? (
                          <Loader
                            type="Oval"
                            color={CustomStyle.colors.white}
                            height={20}
                            width={20}
                          />
                        ) : (
                          "Book Order"
                        )}
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col className="p-0" md="8">
                <h5
                  style={{
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <b>All Products ({productsForDelivery.length})</b>
                </h5>

                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {productsForDelivery.map((itm, i) => {
                    return (
                      <li
                        key={i.toString()}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        <div className="media">
                          <img
                            src={itm?.productImg}
                            style={{
                              width: 50,
                              height: 50,
                            }}
                          />
                          <div className="media-content pl-2">
                            <div>
                              <span>Name: </span>
                              <b>{itm?.productName || "unknown"}</b>
                            </div>
                            <div>
                              <span>Quantity: </span>
                              <b>x{itm?.quantity || 0}</b>
                            </div>
                            {/* <div>
															<span>Price: </span>
															<b>
																{Helper.formatToNaira(
																	itm?.price ||
																		0
																)}
															</b>
														</div> */}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          </Container>
        ) : null}
      </Modal>

      <Modal
        isOpen={confirmData && confirmData?._id ? true : false}
        onRequestClose={() => {
          setConfirmData({});
          setConfirmLoading(false);
        }}
        style={customStyles}
        contentLabel="Confirm Order Modal"
      >
        {confirmData && confirmData?._id ? (
          <div className="px-3">
            <p>Are you sure you want to confirm order?</p>
            <Container fluid>
              <Row>
                <Col className="p-0" md="4" />
                <Col className="p-0" md="4">
                  <Button
                    onClick={() => {
                      setConfirmData({});
                      setConfirmLoading(false);
                    }}
                    className="btn-fill form-control pull-right"
                    variant="default"
                    type="button"
                  >
                    Close
                  </Button>
                </Col>
                <Col className="p-0 pl-2" md="4">
                  <Button
                    className="btn-fill form-control"
                    variant="info"
                    type="button"
                    disabled={confirmLoading}
                    onClick={() => confirmOrderSubmit(confirmData?._id)}
                  >
                    {confirmLoading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </Modal>

      <Modal
        isOpen={shipData && shipData?._id ? true : false}
        onRequestClose={() => {
          setShipData({});
          setShipLoading(false);
        }}
        style={customStyles}
        contentLabel="Ship Order Modal"
      >
        {shipData && shipData?._id ? (
          <div className="px-3">
            {shipData?.deliveryOption != "pick-up" ? (
              <>
                <p>Are you sure you want to ship order?</p>
                <Form.Control
                  placeholder="Enter Tracking ID (Optional)"
                  type="text"
                  required
                  ref={trackInput}
                ></Form.Control>
              </>
            ) : (
              <p>Are you sure you want to confirm pickup?</p>
            )}
            <Container fluid className="pt-5">
              <Row>
                <Col className="p-0" md="4" />
                <Col className="p-0" md="4">
                  <Button
                    onClick={() => {
                      setShipData({});
                      setShipLoading(false);
                    }}
                    className="btn-fill form-control pull-right"
                    variant="default"
                    type="button"
                  >
                    Close
                  </Button>
                </Col>
                <Col className="p-0 pl-2" md="4">
                  <Button
                    className="btn-fill form-control"
                    variant="primary"
                    type="button"
                    disabled={shipLoading}
                    onClick={() => shipOrderSubmit(shipData?._id)}
                  >
                    {shipLoading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </Modal>
      <Modal
        isOpen={shipInterstateData && shipInterstateData?._id ? true : false}
        onRequestClose={() => {
          setShipInterstateData({});
          setShipLoading(false);
        }}
        style={customStyles}
        contentLabel="Ship Order Modal"
      >
        {shipInterstateData && shipInterstateData?._id ? (
          <div className="px-3">
            <>
              <p>Are you sure you want to ship order?</p>
            </>

            <Container fluid className="pt-5">
              <Row>
                <Col className="p-0" md="4" />
                <Col className="p-0" md="4">
                  <Button
                    onClick={() => {
                      setShipInterstateData({});
                      setShipLoading(false);
                    }}
                    className="btn-fill form-control pull-right"
                    variant="default"
                    type="button"
                  >
                    Close
                  </Button>
                </Col>
                <Col className="p-0 pl-2" md="4">
                  <Button
                    className="btn-fill form-control"
                    variant="primary"
                    type="button"
                    disabled={shipLoading}
                    onClick={() => bookInterstateOrder()}
                  >
                    {shipLoading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </Modal>

      <Modal
        isOpen={completeData && completeData?._id ? true : false}
        onRequestClose={() => {
          setCompleteData({});
          setCompleteLoading(false);
        }}
        style={customStyles}
        contentLabel="Complete Order Modal"
      >
        {completeData && completeData?._id ? (
          <div className="px-3">
            <p>Are you sure you want to complete order?</p>
            <Container fluid>
              <Row>
                <Col className="p-0" md="4" />
                <Col className="p-0" md="4">
                  <Button
                    onClick={() => {
                      setCompleteData({});
                      setCompleteLoading(false);
                    }}
                    className="btn-fill form-control pull-right"
                    variant="default"
                    type="button"
                  >
                    Close
                  </Button>
                </Col>
                <Col className="p-0 pl-2" md="4">
                  <Button
                    className="btn-fill form-control"
                    variant="success"
                    type="button"
                    disabled={completeLoading}
                    onClick={() => completeOrderSubmit(completeData?._id)}
                  >
                    {completeLoading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Complete Order"
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </Modal>

      <Modal
        isOpen={declineData && declineData?._id ? true : false}
        onRequestClose={() => {
          setDeclineData({});
          setDeclineLoading(false);
        }}
        style={customStyles}
        contentLabel="Decline Order Modal"
      >
        {declineData && declineData?._id ? (
          <div className="px-3">
            <p>Are you sure you want to decline order?</p>
            <Container fluid>
              <Row>
                <Col className="p-0" md="4" />
                <Col className="p-0" md="4">
                  <Button
                    onClick={() => {
                      setDeclineData({});
                      setDeclineLoading(false);
                    }}
                    className="btn-fill form-control pull-right"
                    variant="default"
                    type="button"
                  >
                    Close
                  </Button>
                </Col>
                <Col className="p-0 pl-2" md="4">
                  <Button
                    className="btn-fill form-control"
                    variant="danger"
                    type="button"
                    disabled={declineLoading}
                    onClick={() => declineOrderSubmit(declineData?._id)}
                  >
                    {declineLoading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Decline Order"
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </Modal>

      <Modal
        isOpen={paymentData && paymentData?._id ? true : false}
        onRequestClose={() => {
          setPaymentData({});
          setPaymentLoading(false);
        }}
        style={customStyles}
        contentLabel="Payment Confirm Modal"
      >
        {paymentData && paymentData?._id ? (
          <div className="px-3">
            <p>Are you sure you want to confirm order cash payment?</p>
            <Container fluid>
              <Row>
                <Col className="p-0" md="4" />
                <Col className="p-0" md="4">
                  <Button
                    onClick={() => {
                      setPaymentData({});
                      setPaymentLoading(false);
                    }}
                    className="btn-fill form-control pull-right"
                    variant="default"
                    type="button"
                  >
                    Close
                  </Button>
                </Col>
                <Col className="p-0 pl-2" md="4">
                  <Button
                    className="btn-fill form-control"
                    variant="warning"
                    type="button"
                    disabled={paymentLoading}
                    onClick={() => paymentOrderSubmit(paymentData?._id)}
                  >
                    {paymentLoading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Confirm Payment"
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </Modal>
    </>
  );
}

export default Orders;
